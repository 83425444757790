import * as Sentry from '@sentry/nuxt';

if (process.env.NODE_ENV === 'production') {
  console.info('Sentry client started');
}

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  // If set up, you can use your runtime config here
  // dsn: useRuntimeConfig().public.sentry.dsn,
  dsn: 'https://951a6ace826b70efe84f5a24200bade0@o4508275504775168.ingest.de.sentry.io/4508275506151504',
  integrations: [Sentry.replayIntegration()],
  // Tracing
  // We recommend adjusting this value in production, or using a tracesSampler for finer control.
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/\w+\.gj\.eugene-blokhin\.com\/api/,
    /^https:\/\/(local\.)?getjob\.cz\/api/,
  ],
  allowUrls: [
    /^https:\/\/\w+\.gj\.eugene-blokhin\.com/,
    /^https:\/\/(local\.)?getjob\.cz/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
